import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AccountsService } from 'app/system/services/accounts.service';

@Injectable()
export class IsAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private service: AccountsService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const navigateIf = route.data['navigateIf'];
    const paramIsAuth = navigateIf.isAuth;
    const paramTo = navigateIf.to;

    return this.service.isAuth().pipe(
      map(result => {
        if (paramTo) {
          if (paramIsAuth === result) {
            this.router.navigate(paramTo);
          }
          return true;
        }
        return result;
      })
    );
  }
}
