import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FormService } from 'app/system/services/form.service';

import { AccountsService } from 'app/system/services/accounts.service';
import { TokenData } from 'app/system/services/token.data';

import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html'
})
export class LoginFormComponent implements OnInit {

  @Output() public submit = new EventEmitter<void>();

  private form: FormGroup;
  private submitted: boolean;

  constructor(
    private builder: FormBuilder,
    private formService: FormService,
    private service: AccountsService,
    private tokenData: TokenData
  ) { }

  public ngOnInit() {

    this.form = this.builder.group({
      email: [this.tokenData.email, [Validators.required, Validators.email]],
      password: [''],
      rememberMe: [this.tokenData.rememberMe]
    });
  }

  private formSubmit() {

    this.submitted = true;
    if (this.form.valid) {

      const value = this.form.value;
      this.tokenData.rememberMe = value.rememberMe;

      // limpa a senha após a requisição
      this.form.get('password').reset();
      this.submitted = false;

      return this.service.signIn(value).pipe(
        tap(result => {
          if (result) {
            this.tokenData.email = value.email;
            this.tokenData.rememberMe = value.rememberMe;
            this.submit.next();
          }
        })
      ).subscribe();
    }
  }
}
