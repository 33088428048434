import { Injectable } from "@angular/core";

export interface ILocalNumberFormat {
  precision?: number;
  decimal?: string;
  thousands?: string;
  symbol?: string;
  showSymbol?: boolean;
  allowNegative?: boolean;
  defaultZero?: boolean;
}

@Injectable()
export class LocalNumberService {

  public format: ILocalNumberFormat;

  constructor() {

    this.format = {
      allowNegative: false,
      defaultZero: true,
      decimal: '.',
      thousands: ',',
      precision: 3,
      showSymbol: false,
      symbol: '$'
    };

    const teste = (1.2).toLocaleString();
    let dec = '.';
    let th = ',';

    if (teste[1] === ',') {
      this.format.decimal = ',';
      this.format.thousands = '.';
    } else {
      this.format.decimal = '.';
      this.format.thousands = ',';     
    }        
  }

  public maskValue(value: string | number, format: ILocalNumberFormat = null): string {

    const fmt = Object.assign({}, this.format, format);

    if (typeof (value) === 'number') {
      value = value.toFixed(fmt.precision);
    }

    value = (value || "0").replace(fmt.symbol, '');

    const strCheck = '0123456789';
    const len = value.length;
    let a = '';
    let t = '';
    let neg = '';

    if (len != 0 && value.charAt(0) == '-') {
      value = value.replace('-', '');
      if (fmt.allowNegative) {
        neg = '-';
      }
    }

    if (len == 0) {
      if (!fmt.defaultZero) return t;
      t = '0.00';
    }

    let i = 0;
    for (i = 0; i < len; i++) {
      if ((value.charAt(i) != '0') && (value.charAt(i) != fmt.decimal)) break;
    }

    for (; i < len; i++) {
      if (strCheck.indexOf(value.charAt(i)) != -1) a += value.charAt(i);
    }

    let n = parseFloat(a);
    n = isNaN(n) ? 0 : n / Math.pow(10, fmt.precision);
    t = n.toFixed(fmt.precision);

    i = fmt.precision == 0 ? 0 : 1;
    let p, d = (t = <any>t.split('.'))[i].substr(0, fmt.precision);
    for (p = (t = t[0]).length; (p -= 3) >= 1;) {
      t = t.substr(0, p) + fmt.thousands + t.substr(p);
    }

    const setSymbol = (v) => {
      if (fmt.showSymbol) {
        if (v.substr(0, fmt.symbol.length) != fmt.symbol) return fmt.symbol + v;
      }
      return v;
    }

    return (fmt.precision > 0)
      ? setSymbol(neg + t + fmt.decimal + d + Array((fmt.precision + 1) - d.length).join('0'))
      : setSymbol(neg + t);
  }
}