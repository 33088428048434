import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FocusFirstDirective } from 'app/system/directives/focus-first.directive';
import { AutofocusDirective } from 'app/system/directives/autofocus.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FocusFirstDirective,
    AutofocusDirective
  ],
  exports: [
    FocusFirstDirective,
    AutofocusDirective
  ]
})
export class SystemDirectivesModule { }
