import { Injectable, Injector } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { tap, map, catchError, filter, finalize } from 'rxjs/operators';

import { InfoModalService, IModalOptions } from 'app/system/components/info-modal/info-modal.service';

import { ColorType } from 'app/system/consts';
import { LoadSpinnerService } from 'app/components/load-spinner/load-spinner.service';
import { HttpClient, HttpResponse, HttpRequest, HttpEventType, HttpErrorResponse, HttpParams } from '@angular/common/http';

export interface IQueryParam {
  name: string;
  value: any;
}

export interface IApiErrorItem {
  field?: string;
  message: string;
}

export enum ApiErrorType {
  Error = 1,
  Info = 2,
  Warning = 3
}

export interface IApiError {
  message: string;
  errors: IApiErrorItem[];
  type?: ApiErrorType;
}

export interface IApiErrorResult {

  error: IApiError;
}

export interface IApiDataResult<T> {

  data?: T;
}

export interface IApiSingleDataResult<T> extends IApiDataResult<T> { }
export interface IApiListDataResult<T> extends IApiDataResult<IList<T>> { }

export interface IApiInsertDataResult<T> extends IApiSingleDataResult<T> {

  location: string;
}

export interface IList<T> {

  list?: T[];
  count?: number;
}

export interface ICustomApiRequestParams<R> {

  description?: string;
  controller?: string;
  action?: string | string[];
  ignoreToken?: boolean;
  ignoreRetry?: boolean;

  requestAccept?: string | string[];
  silentErrorModal?: boolean;
  raiseException?: boolean;
  errorTitle?: string;

  response?: HttpResponse<R>;
  requestInit?: any;
}

// export const isApiGetRequestParams = <R>(pet: ICustomApiRequestParams<R>): pet is IApiGetRequestParams<R> =>
//   'id' in pet || 'queryParams' in pet;
// export const isApiPostRequestParams = <O, R>(pet: ICustomApiRequestParams<R>): pet is IApiPostRequestParams<O, R> =>
//   (pet as ICustomApiRequestParams<R>).method === 'POST';
// export  const isApiUpdateRequestParams = <O, R>(pet: ICustomApiRequestParams<R>): pet is IApiUpdateRequestParams<O, R> =>
//   (pet as ICustomApiRequestParams<R>).method === 'PUT';
// export const isApiDeleteRequestParams = <R>(pet: ICustomApiRequestParams<boolean>): pet is IApiDeleteRequestParams =>
//   (pet as ICustomApiRequestParams<boolean>).method === 'DELETE';

export interface IApiGetRequestParams<R> extends ICustomApiRequestParams<R> {
  id?: string | number;
  queryParams?: IQueryParam | IQueryParam[];
}

export interface IApiPostRequestParams<O, R> extends ICustomApiRequestParams<R> {
  object?: O;
}

export interface IApiUpdateRequestParams<O, R> extends ICustomApiRequestParams<R> {
  id: string | number;
  object?: O;
}

export interface IApiDeleteRequestParams extends ICustomApiRequestParams<boolean> {
  id?: string | number;
  deleteString?: string;
}

export type IApiRequestParams<R> = IApiGetRequestParams<R>
  | IApiPostRequestParams<any, R>
  | IApiUpdateRequestParams<any, R>
  | IApiDeleteRequestParams;

export class ApiHttpRequest<R> extends HttpRequest<R> {
  public apiParams?: IApiRequestParams<R>;
}

@Injectable()
export class ApiService {

  private readonly BASE_URL: string = '/api';
  private readonly DATA_DELAY: number = 50;
  private readonly CREATE_ACTION: string = 'create';

  private modalService: InfoModalService;
  private http: HttpClient;
  private loadSpinner: LoadSpinnerService;

  constructor(
    injector: Injector
  ) {
    this.modalService = injector.get(InfoModalService);
    this.http = injector.get(HttpClient);
    this.loadSpinner = injector.get(LoadSpinnerService);
  }

  private getQueryParams(queryParams: IQueryParam | IQueryParam[]): string {

    const query: IQueryParam[] = [];
    if (queryParams instanceof Array) {
      query.push.apply(query, queryParams);
    } else if (queryParams) {
      query.push(queryParams);
    }
    const queryStr = query.filter(p => p.value).map(p => `${p.name}=${p.value}`).join('&');
    return queryStr === '' ? '' : `?${queryStr}`;
  }

  public getApiErrorFromCatch(error: HttpErrorResponse): IApiError {

    const mapped = error.error; // this.mapType<IApiErrorResult>(error);
    const result = mapped ? mapped.error : <IApiError>{
      message: error.statusText
    };
    result.type = (mapped && mapped.error && mapped.error.type) || ApiErrorType.Error;
    return result;
  }

  public getApiErrorModal(error: IApiError): IModalOptions {

    let message = `<p><strong>${error.message}</strong></p>`;
    if (error.errors) {
      message += error.errors.map(x => `<p>${x.message}</p>`).join('');
    }

    let modalType = ColorType.Danger;
    switch (error && error.type) {
      case ApiErrorType.Info: modalType = ColorType.Info; break;
      case ApiErrorType.Warning: modalType = ColorType.Warning; break;
    }

    return <IModalOptions>{
      message: message,
      type: modalType
    };
  }

  private mapError(error: HttpErrorResponse, params: ICustomApiRequestParams<any>): Observable<Response> {

    if (!params.silentErrorModal) {

      const errorResult = this.getApiErrorFromCatch(error);
      const modal = this.getApiErrorModal(errorResult);
      modal.title = params.errorTitle;
      this.modalService.show(modal);
    }

    if (params.raiseException) {
      return throwError(error);
    } else {
      return of(null);
    }
  }

  public createRequest<T>(method: 'GET' | 'DELETE' | 'POST' | 'PUT', params: IApiRequestParams<T>): ApiHttpRequest<T> {

    const segments: string[] = [];

    if (typeof (params.action) === 'string') {
      segments.push(params.action);
    } else if (params.action instanceof Array) {
      segments.push.apply(segments, params.action);
    }

    const getParams = (params as IApiGetRequestParams<T>);
    const id = getParams.id;
    if (typeof (id) === 'string') {
      segments.push(id);
    } else if (id) {
      segments.push(id.toString());
    }

    const url = `${this.BASE_URL}/${params.controller}${segments.length > 0 ? `/${segments.join('/')}`
      : ''}${this.getQueryParams(getParams.queryParams)}`;

    const init = params && params.requestInit;

    let result: ApiHttpRequest<T>;
    if (method === 'GET' || method === 'DELETE') {
      result = new ApiHttpRequest<T>(method, url, init);
    } else if (method === 'POST' || method === 'PUT') {
      result = new ApiHttpRequest<T>(method, url, (params as IApiPostRequestParams<any, T>).object, init);
    }

    result.apiParams = params;
    return result;
  }

  public mapResult<T>(entity: T, index: number): void { }

  public request<T>(request: ApiHttpRequest<T>): Observable<T> {

    this.loadSpinner.loading(true);
    return this.http.request<T>(request).pipe(
      filter(x => x.type === HttpEventType.Response),
      catchError(x => {
        this.loadSpinner.loading(false);
        return this.mapError(x, request.apiParams);
      }),
      filter(x => x !== null),
      map(x => {
        if (x instanceof HttpResponse) {
          request.apiParams.response = x;
          return x.body as T;
        }
      }),
      finalize(() => this.loadSpinner.loading(false))
    );
  }

  public get<T>(params?: IApiGetRequestParams<T>): Observable<IApiSingleDataResult<T>> {

    const p: IApiGetRequestParams<T> = params || {} as IApiGetRequestParams<T>;
    p.errorTitle = p.errorTitle || 'Get';

    return this.request(this.createRequest<IApiSingleDataResult<T>>('GET', params)).pipe(
      map((singleResult, index) => {
        if (singleResult) {
          this.mapResult(singleResult.data, index);
        }
        return singleResult;
      })
    );
  }

  public list<T>(params?: IApiGetRequestParams<T>): Observable<IApiListDataResult<T>> {

    const p: IApiGetRequestParams<T> = params || <IApiGetRequestParams<T>>{};
    p.errorTitle = p.errorTitle || 'List';

    return this.request<IApiListDataResult<T>>(this.createRequest('GET', p)).pipe(
      map((listResult) => {
        if (listResult) {
          listResult.data.list.map((entity, index) => {
            this.mapResult(entity, index);
            return entity;
          });
        }
        return listResult;
      })
    );
  }

  public post<O, T>(params?: IApiPostRequestParams<O, T>): Observable<T> {

    const p: IApiPostRequestParams<O, T> = params || <IApiPostRequestParams<O, T>>{};
    p.errorTitle = p.errorTitle || 'Post';

    return this.request<T>(this.createRequest('POST', p));
  }

  public create<T>(params?: IApiGetRequestParams<T>): Observable<IApiSingleDataResult<T>> {

    const p: IApiGetRequestParams<T> = params || <IApiGetRequestParams<T>>{};
    p.action = p.action || this.CREATE_ACTION;
    p.errorTitle = p.errorTitle || 'Create';

    return this.get(p);
  }

  public insert<O, R>(params?: IApiPostRequestParams<O, IApiInsertDataResult<R>>): Observable<IApiInsertDataResult<R>> {

    const p: IApiPostRequestParams<O, IApiInsertDataResult<R>> = params || <IApiPostRequestParams<O, IApiInsertDataResult<R>>>{};
    p.errorTitle = p.errorTitle || 'Insert';

    return this.request<IApiInsertDataResult<R>>(this.createRequest('POST', p)).pipe(
      tap(result => {
        result.location = params.response.headers.get('location');
      })
    );
  }

  public update<O, R>(params?: IApiPostRequestParams<O, IApiSingleDataResult<R>>): Observable<IApiSingleDataResult<R>> {

    const p: IApiPostRequestParams<O, IApiSingleDataResult<R>> = params || <IApiPostRequestParams<O, IApiSingleDataResult<R>>>{};
    p.errorTitle = p.errorTitle || 'Update';

    const a = this.createRequest('PUT', p);

    return this.request<IApiSingleDataResult<R>>(a);
  }

  public delete(params?: IApiDeleteRequestParams): Observable<void> {

    const p: IApiDeleteRequestParams = params || <IApiDeleteRequestParams>{};
    p.errorTitle = p.errorTitle || 'Delete';

    return new Observable<void>(s => {
      this.modalService.show({
        title: `Delete ${p.description}`,
        message: `Confirm delete${p.deleteString ? ` ${p.deleteString}` : ''}?`,
        confirmType: ColorType.Danger,
        confirmText: 'Delete',
        type: ColorType.Primary,
        onClose: () => s.complete(),
        onConfirm: () => {
          this.request(this.createRequest('DELETE', params)).subscribe(deleted => {
            s.next();
            s.complete();
          }, error => {
            s.error(error);
          });
        }
      });
    });
  }
}

