import { Component, ElementRef } from '@angular/core';

import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-login-form-modal',
  templateUrl: './login-form-modal.component.html'
})
export class LoginFormModalComponent {

  public onSubmit = new Subject<boolean>();

  constructor(
    private modalRef: BsModalRef,
    private router: Router
  ) { }

  private submit(value: boolean) {

    this.onSubmit.next(value);
    this.modalRef.hide();
  }
  }
