import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadSpinnerService } from './load-spinner.service';

import { LoadSpinnerComponent } from './load-spinner.component';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    LoadSpinnerService
  ],
  declarations: [
    LoadSpinnerComponent
  ],
  exports: [
    LoadSpinnerComponent
  ]
})
export class LoadSpinnerModule { }
