import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { isEmpty, map } from 'rxjs/operators';

import { ReceiverService } from 'app/system/services/receiver.service';

@Injectable()
export class SubdomainGuard implements CanActivate {

  constructor(
    private router: Router,
    private service: ReceiverService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.service.getReceiver().pipe(
      isEmpty(),
      map(empty => {
        if (empty) {
          window.location.href = 'https://headsoft.com.br';
          // this.router.navigateByUrl('/notfound');
        }
        return !empty;
      })
    );
  }
}
