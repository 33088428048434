import { Directive, ElementRef, AfterViewInit, DoCheck, Renderer } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective implements AfterViewInit, DoCheck {

  private lastVisible: boolean = false;
  private initialised: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer
  ) { }

  ngAfterViewInit() {

    this.initialised = true;
    this.ngDoCheck();
  }

  ngDoCheck() {

    if (!this.initialised) {
      return;
    }
    const visible = !!this.elementRef.nativeElement.offsetParent;
    if (visible && !this.lastVisible) {
//      setTimeout(() => { this.elementRef.nativeElement.focus(); }, 1);

      this.renderer.invokeElementMethod(this.elementRef.nativeElement, 'focus', []);
      this.renderer.invokeElementMethod(this.elementRef.nativeElement, 'select', []);

    }
    this.lastVisible = visible;
  }

}
