import { CanLoad, Route } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReceiverService } from '../services/receiver.service';

@Injectable()
export class CanLoadVGM implements CanLoad {

  constructor(
    private receiver: ReceiverService
  ) { }

  canLoad(route: Route): Observable<boolean> {

    return this.receiver.getReceiver().pipe(
      map(x => x.modules.vgm)
    );
  }
}
