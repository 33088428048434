import { Injector } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, catchError, startWith } from 'rxjs/operators';

import { ModelApiService, ApiServiceOptions } from 'app/system/services/model-api.service';
import { IApiSingleDataResult, IApiPostRequestParams } from 'app/system/services/api.service';
import { TokenData } from 'app/system/services/token.data';
import { InfoModalService } from 'app/system/components/info-modal/info-modal.service';
import { ITokenRefreshHandler } from 'app/system/services/api-service.interceptor';
import { HttpErrorResponse } from '@angular/common/http';

export interface IUser {
  email: string;
  password: string;
}

export interface IApplyReset {
  email: string;
  refresh_token: string;
  password: string;
  password_confirm: string;
}

export interface IRefreshTokenDto {
  email: string;
  refresh_token: string;
}

const STATUS_NOT_ACCEPTABLE = 406;

@ApiServiceOptions({ description: 'Accounts', controller: 'accounts' })
export class AccountsService extends ModelApiService<TokenData> implements ITokenRefreshHandler {

  private STATUS_NOT_FOUND = 404;
  private STATUS_NOT_ACCEPTABLE = 406;

  public tokenData: TokenData;

  private modalService: InfoModalService;

  constructor(
    injector: Injector
  ) {
    super(injector);
    this.tokenData = injector.get(TokenData);
    this.modalService = injector.get(InfoModalService);
  }

  private getTokenData(): IRefreshTokenDto {

    const token = this.tokenData.decode();
    if (token) {
      return { email: token.email, refresh_token: this.tokenData.refresh_token };
    } else {
      return null;
    }
  }

  private mapTokenData = (token: IApiSingleDataResult<TokenData>): boolean => {

    this.tokenData.set(token.data);
    return true;
  }

  public refreshToken(): Observable<boolean> {

    const tokenDto = this.getTokenData();
    const p = <IApiPostRequestParams<IRefreshTokenDto, IApiSingleDataResult<TokenData>>>{};
    p.ignoreToken = true;
    p.action = p.action || 'refresh';
    p.object = p.object || tokenDto;
    p.raiseException = true;
    p.silentErrorModal = true;

    return this.post(p).pipe(
      map(this.mapTokenData),
      catchError(error => {
        this.tokenData.clearToken();
        return of(false);
      }),
    );
  }

  public passwordRecovery(user: IUser): Observable<boolean> {

    return super.post({
      action: 'recovery',
      object: user,
      errorTitle: 'Reset',
      ignoreToken: true,
      raiseException: true
    }).pipe(
      map(result => true),
      catchError(e => of(false))
    );
  }

  public changePassword(user: IApplyReset): Observable<boolean> {

    const updateToken = user.refresh_token != null;
    return super.post({
      action: 'password',
      object: user,
      errorTitle: 'Reset',
      ignoreToken: updateToken,
      raiseException: true
    }).pipe(
      map(result => true),
      catchError(e => of(false))
    );
  }

  public register(user: IUser): Observable<boolean> {

    return super.post({
      action: 'register',
      object: user,
      errorTitle: 'Register',
      ignoreToken: true,
      silentErrorModal: true,
      raiseException: true
    }).pipe(
      map(result => true),
      catchError((response: HttpErrorResponse) => {

        const error = this.service.getApiErrorFromCatch(response);
        if (response.status === STATUS_NOT_ACCEPTABLE) {
          error.errors = [
            { message: 'Would you like to receive a e-mail to reset your password?' }
          ];
        }

        const modal = this.service.getApiErrorModal(error);
        if (response.status === STATUS_NOT_ACCEPTABLE) {
          modal.onConfirm = () => {
            this.passwordRecovery(user).subscribe(() => {
              this.modalService.show({
                message: '<p>Please verify your e-mail inbox.</p><p><small>Tip: Also check your junk email inbox.</small></p>'
              });
            });
          };
        }

        modal.title = 'Register';
        this.modalService.show(modal);

        return of(false);
      })
    );
  }

  public activate(activation: IApplyReset): Observable<void> {

    return super.post({
      action: 'activate',
      object: activation,
      errorTitle: 'Activation',
      ignoreToken: true,
      raiseException: true,
      silentErrorModal: true
    }).pipe(
      map(() => null)
    );
  }

  public reactivate(user: IUser): Observable<void> {

    return super.post({
      action: 'reactivate',
      object: user,
      errorTitle: 'Reactivation',
      ignoreToken: true
    }).pipe(
      map(() => null)
    );
  }

  public signIn(user: IUser): Observable<boolean> {

    const request = {
      action: 'signin',
      object: user,
      ignoreToken: true,
      raiseException: true,
      silentErrorModal: true
    };

    return super.post(request).pipe(
      map(this.mapTokenData),
      catchError((response: HttpErrorResponse) => {

        const error = this.service.getApiErrorFromCatch(response);
        if (response.status === STATUS_NOT_ACCEPTABLE) {
          error.errors = [
            { message: 'Would you like to resend your confirmation email to activate your account?' }
          ];
        }

        const modal = this.service.getApiErrorModal(error);
        if (response.status === STATUS_NOT_ACCEPTABLE) {
          modal.onConfirm = () => {
            this.reactivate(user).subscribe(() => {
              this.modalService.show({
                message: '<p>Please verify your e-mail inbox.</p><p><small>Tip: Also check your junk email inbox.</small></p>'
              });
            });
          };
        }

        modal.title = 'Sign In';
        this.modalService.show(modal);
        return of(false);
      })
    );
  }

  public signOut(): Observable<boolean> {

    const tokenDto = this.getTokenData();
    return super.post({
      action: 'signout',
      object: tokenDto,
      errorTitle: 'Sign Out',
      silentErrorModal: true,
      raiseException: true
    }).pipe(
      map(result => {
        this.tokenData.clearToken();
        return true;
      }),
      catchError(e => of(false))
    );
  }

  public isAuth(): Observable<boolean> {

    const p = this.getParams();
    p.action = 'isauth';
    p.errorTitle = 'Auth';
    p.silentErrorModal = true;
    p.raiseException = true;
    p.ignoreToken = false;
    p.ignoreRetry = true;

    return this.service.get<boolean>(p).pipe(
      map(() => true),
      catchError(e => of(false))
    );
  }
}
