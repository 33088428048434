import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';

import { InfoModalContainerComponent } from './info-modal-container.component';
import { InfoModalComponent } from './info-modal.component';
import { InfoModalService } from './info-modal.service';

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot()
  ],
  declarations: [
    InfoModalContainerComponent,
    InfoModalComponent
  ],
  entryComponents: [
    InfoModalComponent
  ],
  providers: [
    InfoModalService
  ],
  exports: [
    InfoModalContainerComponent
  ]
})
export class InfoModalModule { }
