import { Component, ViewChild, ComponentRef, AfterViewInit, ElementRef } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { IModalOptions } from './info-modal.service';
import { FormService } from 'app/system/services/form.service';
import { ColorTypes, IColorScheme, ColorType } from 'app/system/consts';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html'
})
export class InfoModalComponent implements AfterViewInit {

  @ViewChild('modal') private modal: ModalDirective;

  private _options: IModalOptions;

  public get options(): IModalOptions {
    return this._options;
  }
  public set options(value: IModalOptions) {
    this.headerColor = ColorTypes[value.type || ColorType.Info];
    this.confirmColor = ColorTypes[value.confirmType || ColorType.Primary];
    this.cancelColor = ColorTypes[value.cancelType || ColorType.Secondary];
    this.closeColor = ColorTypes[value.cancelType || ColorType.Secondary];
    this._options = value;
  }

  public componentRef: ComponentRef<InfoModalComponent>;
  public count = 1;
  private confirmed: boolean;

  private headerColor: IColorScheme;
  private confirmColor: IColorScheme;
  private cancelColor: IColorScheme;
  private closeColor: IColorScheme;

  constructor(
    private sanitizer: DomSanitizer,
    private formService: FormService,
    private elementRef: ElementRef
  ) { }

  public ngAfterViewInit(): void {

    this.confirmed = false;
    this.modal.show();
  }

  private confirm() {

    this.confirmed = true;
    this.modal.hide();
  }

  private onShown() {

    this.formService.focusFirst(this.elementRef, (e: any) => {
      return e.localName === 'button' && !e.children.length;
    });
  }

  private onHidden(event) {

    this.componentRef.destroy();
    if (this.confirmed && this.options.onConfirm) {
      this.options.onConfirm(this.options.data);
    } else if (!this.confirmed && this.options.onClose) {
      this.options.onClose(this.options.data);
    }
  }

  private getTitle() {

    return this.options.title ? this.options.title : 'Information';
  }

  private getConfirmText() {

    return this.options.confirmText ? this.options.confirmText : 'Confirm';
  }

  private getCancelText() {

    return this.options.cancelText ? this.options.cancelText : (this.options.onConfirm ? 'Cancel' : 'Close');
  }

  private getHeaderClass() {

    const css = {};
    css[`bg-${this.headerColor.background}`] = true;
    css[`text-${this.headerColor.text}`] = true;
    return css;
  }

  private getCloseClass() {

    const css = {};
    css[`text-${this.closeColor.text}`] = true;
    return css;
  }

  private getConfirmClass() {

    const css = {};
    css[`btn-${this.confirmColor.background}`] = true;
    return css;
  }

  private getCancelClass() {

    const css = {};
    css[`btn-${this.cancelColor.background}`] = true;
    return css;
  }

  private getSize() {

    const css = {};
    css[`modal-${this.options.size || 'md'}`] = true;
    return css;
  }

  private getTop() {

    return `${this.count * 30}px auto`;
  }
}
