import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';

import { SystemDirectivesModule } from 'app/system/directives/system-directives.module';

import { LoginFormComponent } from './login-form.component';
import { LoginFormModalComponent } from './login-form-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ModalModule,
    SystemDirectivesModule
  ],
  declarations: [
    LoginFormComponent,
    LoginFormModalComponent
  ],
  entryComponents: [
    LoginFormModalComponent
  ],
  exports: [
    LoginFormComponent,
    LoginFormModalComponent
  ]
})
export class LoginFormModule { }
