import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  IApiSingleDataResult,
  ApiService,
  IApiGetRequestParams,
  IApiListDataResult,
  IApiPostRequestParams,
  IApiDeleteRequestParams,
  IApiInsertDataResult,
  ICustomApiRequestParams,
  IApiUpdateRequestParams
} from 'app/system/services/api.service';
import { SnackbarService } from 'app/components/snackbar/snackbar.service';

export interface IApiOptions {
  description: string;
  controller: string;
}

export function ApiServiceOptions(options: IApiOptions) {
  return function (target) {
    target.prototype.modelDataService = options;
  };
}

@Injectable()
export class ModelApiService<T> {

  public service: ApiService;
  private snackbarService: SnackbarService;

  constructor(
    injector: Injector
  ) {
    this.service = injector.get(ApiService);
    this.snackbarService = injector.get(SnackbarService);
  }

  private get options(): IApiOptions {
    return this['modelDataService'];
  }

  public getParams<R>(params?: ICustomApiRequestParams<R>): any {

    const p: ICustomApiRequestParams<R> = params || <ICustomApiRequestParams<R>>{};
    if (!p.controller) {
      p.controller = this.options.controller;
    }
    if (!p.description) {
      p.description = this.options.description;
    }
    return p;
  }

  public customRequest<R>(method: 'GET' | 'DELETE' | 'POST' | 'PUT', params?: IApiGetRequestParams<R>): Observable<IApiSingleDataResult<R>> {

    return this.service.request(this.service.createRequest<IApiSingleDataResult<R>>(method,
      this.getParams(params)));
  }

  public get(params?: IApiGetRequestParams<T>): Observable<IApiSingleDataResult<T>> {

    return this.service.get<T>(this.getParams(params));
  }

  public list(params?: IApiGetRequestParams<T>): Observable<IApiListDataResult<T>> {

    return this.service.list<T>(this.getParams(params));
  }

  public create(params?: IApiGetRequestParams<T>): Observable<IApiSingleDataResult<T>> {

    return this.service.create<T>(this.getParams(params));
  }

  public post<Object, Result>(params?: IApiPostRequestParams<Object, Result>
      | IApiUpdateRequestParams<Object, Result>): Observable<Result> {

    return this.service.post<Object, Result>(this.getParams(params as ICustomApiRequestParams<Result>));
  }

  public insert(params?: IApiPostRequestParams<T, T>, ignoreSnack?: boolean): Observable<IApiInsertDataResult<T>> {

    const p = this.getParams(params);
    return this.service.insert<T, T>(p).pipe(
      tap(x => {
        if (!ignoreSnack) {
          this.snackbarService.notify(`${p.description} successfully added!`);
        }
      }));
  }

  public update(params?: IApiUpdateRequestParams<T, T>, ignoreSnack?: boolean): Observable<IApiSingleDataResult<T>> {

    const p = this.getParams(params);
    return this.service.update<T, T>(p).pipe(tap(x => {
      if (!ignoreSnack) {
        this.snackbarService.notify(`${p.description} successfully updated!`);
      }
    }));
  }

  public delete(params?: IApiDeleteRequestParams, ignoreSnack?: boolean): Observable<void> {

    const p = this.getParams(params);
    return this.service.delete(p).pipe(tap(x => {
      if (!ignoreSnack) {
        this.snackbarService.notify(`${p.description} successfully removed!`);
      }
    }));
  }
}
