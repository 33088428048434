import { LoadSpinnerService } from './load-spinner.service';
import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-load-spinner',
  templateUrl: './load-spinner.component.html',
  styleUrls: ['./load-spinner.component.scss'],
  animations: [
    trigger('spinner-visibility', [
      state('hidden', style({
        opacity: 0,
        "display": "none"
      })),
      state('visible', style({
        opacity: 1,
        "display": "block"
      })),
      transition('hidden => visible', animate('300ms 50ms ease-in')),
      transition('visible => hidden', animate('300ms 50ms ease-out'))
    ])
  ]
})
export class LoadSpinnerComponent implements OnInit {

  spinnerVisibility: string = 'hidden';
  count: number = 0;

  constructor(
    private loadSpinnerService: LoadSpinnerService
  ) { }

  ngOnInit() {

    this.loadSpinnerService.loader.subscribe(show => {
      if (show === true) {
        this.count = this.count + 1;
        if (this.count <= 1) {
          this.spinnerVisibility = 'visible';
        }
      } else {
        this.count = this.count - 1;
        if (this.count <= 0) {
          this.spinnerVisibility = 'hidden';
        }
      }
    })
  }

}
