import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class LoadSpinnerService {

  loader = new EventEmitter<boolean>();
  
  constructor() { }

  loading(show: boolean) {
    
    this.loader.emit(show);
  }
      
}
