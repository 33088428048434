import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorType } from 'app/system/consts';

export interface IInfoPage {
  title: string;
  subtitle: string;
  message: string;
  type: ColorType;
}

@Component({
  templateUrl: './info-page.component.html'
})
export class InfoPageComponent implements OnInit {

  private title: string;
  private subtitle: string;
  private message: string;
  private color: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  public ngOnInit(): void {

    const info = <IInfoPage>this.route.snapshot.data.info;
    let colorType;

    if (info) {

      this.title = info.title;
      this.subtitle = info.subtitle;
      this.message = info.message;
      colorType = info.type;
    }

    const error = (<any>this.router).notFoundError;
    if (error) {
      this.title = 'Operation aborted';
      const msg = error.json();
      this.message = msg && msg.error && msg.error.message;
      (<any>this.router).notFoundError = null;
    }

    this.title = this.title || 'Information';
    this.subtitle = this.subtitle || 'Hello!';
    this.message = this.message || 'How are you?';

    colorType = colorType || ColorType.Info;
    this.color = ColorType[colorType].toLowerCase();
  }

  private getType(): string {

    return;
  }
}
