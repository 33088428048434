import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';

import { TokenData } from 'app/system/services/token.data';
import { ApiHttpRequest } from 'app/system/services/api.service';
import { IUser } from 'app/system/services/accounts.service';
import { BsModalService } from 'ngx-bootstrap';
import { LoginFormModalComponent } from 'app/system/components/login-form/login-form-modal.component';

export const TOKEN_REFRESH_HANDLER = new InjectionToken('TOKEN_REFRESH_HANDLER');

export interface IRefreshTokenDto {
  email: string;
  refresh_token: string;
}

export interface ITokenRefreshHandler {

  refreshToken(): Observable<boolean>;
  signIn(user: IUser): Observable<boolean>;
}

@Injectable()
export class ApiServiceInterceptor implements HttpInterceptor {

  constructor(
    private token: TokenData,
    private modal: BsModalService,
    @Inject(TOKEN_REFRESH_HANDLER) private refreshHandler: ITokenRefreshHandler
  ) { }

  private applyCredentials(req: ApiHttpRequest<any>): HttpRequest<any> {

    let newReq = req;
    const token = this.token && this.token.access_token;
    if (token != null) {
      newReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
      newReq.apiParams = req.apiParams;
    }
    return newReq;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const params = req instanceof ApiHttpRequest ? req.apiParams : {};

    return next.handle(this.applyCredentials(req)).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401 && !params.ignoreToken) {
          return (this.refreshHandler.refreshToken.call(this.refreshHandler) as Observable<boolean>).pipe(
            flatMap(refreshResult => {
              if (refreshResult) {
                return this.intercept(req, next);
              } else {
                if (params.ignoreRetry) {
                  return throwError(error);
                } else {
                  const form = this.modal.show(LoginFormModalComponent).content as LoginFormModalComponent;
                  return form.onSubmit.pipe(
                    flatMap(submitResult => {
                      if (submitResult) {
                        return this.intercept(req, next);
                      } else {
                        return throwError(error);
                      }
                    })
                  );
                }
              }
            }),
          );
        }

        return throwError(error);
      })
    );
  }
}
