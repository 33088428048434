import { Component, OnInit } from '@angular/core';
import { ReceiverService } from 'app/system/services/receiver.service';
import { TokenData } from 'app/system/services/token.data';
import { AccountsService } from 'app/system/services/accounts.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  private receiverLoaded: boolean;
  private hasVGMModule: boolean;
  private receiver: string;
  private manualDraftDisabled: boolean;

  constructor(
    private accounts: AccountsService,
    private router: Router,
    private tokenData: TokenData,
    private route: ActivatedRoute,
    private receiverService: ReceiverService
  ) {
  }

  ngOnInit(): void {

    this.receiverService.getReceiver().subscribe(s => {
      this.hasVGMModule = s.modules.vgm;
      this.receiverLoaded = true;
      this.receiver = `${s.name} `;
      this.manualDraftDisabled = s.locks.manualDraftDisabled;
    });
  }

  public signOut() {

    this.accounts.signOut().subscribe(s => {
      this.router.navigate(['/login/signin']);
    });
    return false; // para não executar o click da anchor
  }

  private signingIn(): boolean {

    return this.router.url === '/login/signin';
  }

  private loggedIn(): boolean {

    return this.tokenData != null
      && this.tokenData.hasAccessToken();
  }

  private getEmail(): string {

    return this.loggedIn() && this.tokenData.decode().email;
  }

  private getReceiverTitle() {
    return this.receiverLoaded ? `${this.receiver} WEB BL` : '';
  }
}
