import { Observable, of } from 'rxjs';
import { map, filter, catchError, finalize } from 'rxjs/operators';

import { ModelApiService, ApiServiceOptions } from 'app/system/services/model-api.service';

export interface ILocks {

  equipmentNotNull: boolean;
  equipmentRule: boolean;
  packageTypeNotNull: boolean;
  packageTypeRule: boolean;
  originalsNotNull: boolean;
  woodPackageNotNull: boolean;
  consigneeNIFCNPJNotNull: boolean;
  dueNotNull: boolean;
  rucNotNull: boolean;
  dueOrRucNotNull: boolean;
  barSealDisabled: boolean;

  sendBookingHouseInfo: boolean;
  manualDraftDisabled: boolean;
  cloneDraftDisabled: boolean;
  draftDeadlineMessage: string;
  vgmDeadlineMessage: string;
  contactMaxLineCount: number;
  contactMaxLineLength: number;
  descriptionOfPackagesMaxLineCount: number;
  descriptionOfPackagesMaxLineLength: number;
 
  bookingPreCarriageBy: boolean;
  bookingReceiptPlace: boolean;
  bookingLoadingPort: boolean;
  bookingDischargePort: boolean;
  bookingDeliveryPlace: boolean;
  bookingOceanVessel: boolean;
  bookingVoyageNumber: boolean;
}

export interface IReceiverModules {
  vgm: boolean;
}

export interface IReceiver {

  name: string;
  domain: string;
  site: string;
  logoStr: string;
  logoContentType: string;
  locks: ILocks;
  version: number;
  modules: IReceiverModules;

}

@ApiServiceOptions({ description: 'Receiver', controller: 'receiver' })
export class ReceiverService extends ModelApiService<IReceiver> {

  private _receiver: IReceiver;
  private _loaded = false;
  public lastError: string;

  public getReceiver(): Observable<IReceiver> {

    const params = this.getParams();
    params.raiseException = true;
    params.ignoreToken = true;
    params.silentErrorModal = true;

    if (this._loaded) {
      return of(this._receiver).pipe(
        filter(f => f != null)
      );
    } else {
      return this.service.get<IReceiver>(params).pipe(
        map(receiver => {
          this._receiver = receiver ? receiver.data : null;
          return this._receiver;
        }),
        catchError((error, c) => {
          this.lastError = error;
          return of(null);
        }),
        filter(f => f != null),
        finalize(() => {
          this._loaded = true;
        })
      );
    }
  }
}
