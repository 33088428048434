import { Injectable, ElementRef } from '@angular/core';
import { AbstractControl, FormGroup, FormArray } from '@angular/forms';

type MathElementFnc = (element: ElementRef) => boolean;

@Injectable()
export class FormService {

  private createChildControl(control: FormArray) {
    return (control as any).__createChildControl();
  }

  public setChildControlFactory(control: FormArray, factory: () => AbstractControl) {
    (control as any).__createChildControl = factory;
  }

  public setValues(control: AbstractControl, value: any) {

    if (control instanceof FormGroup) {
      if (value != null) {
        Object.keys(value).forEach(name => {
          if (control.contains(name)) {
            this.setValues(control.get(name), value[name]);
          }
        });
      }
    } else if (control instanceof FormArray) {
      const length = value ? value.length : 0;
      // Remove excess controls from the array
      while (control.length > length) {
        control.removeAt(control.length - 1);
      }
      // Add missing controls
      while (control.length < length) {
        control.push(this.createChildControl(control));
      }
      // Update all the values in the array
      for (let i = 0; i < length; ++i) {
        this.setValues(control.at(i), value[i]);
      }
    } else {
      control.setValue(value);
    }
  }

  public isInvalid(submitted: boolean, form: FormGroup, formControlName?: string, errorName?: string): boolean {

    const control = formControlName ? form.get(formControlName) : form;
    return submitted && control.enabled && !control.valid && (!errorName || (control.hasError(errorName)));
  }

  private _getInputElement(nativeElement: any, selector: string | MathElementFnc): any {

    if (!nativeElement || !nativeElement.children) {
      return undefined;
    }
    if (!nativeElement.children.length && !nativeElement.hidden
      && ((typeof (selector) !== 'string' && selector(nativeElement))
        || nativeElement.localName === selector)) {
      return nativeElement;
    }

    let input;

    [].slice.call(nativeElement.children).every(c => {
      input = this._getInputElement(c, selector);
      if (input) {
        return false; // break
      }
      return true; // continue!
    });

    return input;
  }

  private _mathElement(nativeElement: any, selector: (element: ElementRef) => boolean, includeHidden: boolean) {

    if (!nativeElement || (!includeHidden && nativeElement.hidden)) {
      return undefined;
    }

    if (selector(nativeElement)) {
      return nativeElement;
    }

    if (!nativeElement.children) {
      return undefined;
    }

    let input;

    [].slice.call(nativeElement.children).every(c => {
      input = this._mathElement(c, selector, includeHidden);
      if (input) {
        return false; // break
      }
      return true; // continue!
    });

    return input;
  }

  public findChild(parentRef: ElementRef, selector: (element: ElementRef) => boolean, includeHidden: boolean = false): any {

    const formChildren = [].slice.call(parentRef.nativeElement.children);
    let result: ElementRef;

    formChildren.every(child => {
      const input = this._mathElement(child, selector, includeHidden);
      if (input) {
        result = input;
        return false; // break!
      }
      return true; // continue!
    });

    return result;
  }

  public focusFirst(elementRef: ElementRef, selector?: string | MathElementFnc, selectAll: boolean = true) {

    if (!selector) {
      selector = 'input';
    }

    let fnc: MathElementFnc;
    if (typeof (selector) === 'string') {
      fnc = (element: any) => element.localName === selector;
    } else {
      fnc = selector;
    }

    const firstInput = this.findChild(elementRef, fnc);
    if (firstInput) {
      firstInput.focus();
      if (selector === 'input' && selectAll) {
        firstInput.select();
      }
    }
  }
}
