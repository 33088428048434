import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DefaultUrlSerializer, UrlTree, UrlSerializer } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { InfoPageComponent } from 'app/system/routes/info-page/info-page.component';
import { FormService } from 'app/system/services/form.service';
import { InfoModalModule } from 'app/system/components/info-modal/info-modal.module';
import { LoadSpinnerModule } from 'app/components/load-spinner/load-spinner.module';
import { SnackbarModule } from 'app/components/snackbar/snackbar.module';

import { SubdomainGuard } from 'app/system/guards/subdomain.guard';
import { ReceiverService } from 'app/system/services/receiver.service';
import { NotFoundGuard } from 'app/system/guards/notfound.guard';
import { TokenData } from 'app/system/services/token.data';
import { ApiService } from 'app/system/services/api.service';
import { AccountsService } from 'app/system/services/accounts.service';
import { ColorType } from 'app/system/consts';
import { IsAuthGuard } from 'app/system/guards/isauth.guard';
import { CanLoadVGM } from 'app/system/guards/can-load-vgm.guard';
import { LocalNumberService } from './system/services/local-number.service';

import { TooltipModule, PaginationModule } from 'ngx-bootstrap';
import { TOKEN_REFRESH_HANDLER, ApiServiceInterceptor } from 'app/system/services/api-service.interceptor';

import { LoginFormModule } from 'app/system/components/login-form/login-form.module';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {

  parse(url: string): UrlTree {
    return super.parse(url.toLowerCase());
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'app', pathMatch: 'full' },
      {
        path: 'app', children:
        [
          { path: '', redirectTo: 'bill-lading', pathMatch: 'full' },
          { path: 'bill-lading', loadChildren: './routes/bill-lading/bill-lading.module#BillLadingModule' },
          { path: 'vgm', loadChildren: './routes/vgm/vgm.module#VGMModule', canLoad: [CanLoadVGM] },
          { path: 'port', loadChildren: './routes/port/port.module#PortModule' },
          { path: 'ocean-vessel', loadChildren: './routes/ocean-vessel/ocean-vessel.module#OceanVesselModule' },
          { path: 'customer', loadChildren: './routes/customer/customer.module#CustomerModule' }
        ],
        canActivate: [SubdomainGuard, IsAuthGuard],
        data: {
          navigateIf: {
            isAuth: false,
            to: ['/login/signin']
          }
        }
      },
      { path: 'login', loadChildren: './system/routes/login/login.module#LoginModule', canActivate: [SubdomainGuard] },
      { path: '**', redirectTo: 'NotFound', pathMatch: 'full', },
      {
        path: 'notfound',
        component: InfoPageComponent,
        data: {
          info: {
            title: 'Not found!',
            subtitle: 'Oh snap!',
            message: 'The requested page was not found on server.',
            type: ColorType.Danger
          }
        },
        canActivate: [NotFoundGuard]
      }
    ], {
      enableTracing: false,
      relativeLinkResolution: 'corrected'
    }),
    InfoModalModule,
    LoadSpinnerModule,
    SnackbarModule,
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    LoginFormModule,
  ],
  providers: [
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    ApiService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiServiceInterceptor, multi: true },
    AccountsService,
    { provide: TOKEN_REFRESH_HANDLER, useExisting: AccountsService },
    TokenData,
    FormService,
    ReceiverService,
    SubdomainGuard,
    IsAuthGuard,
    NotFoundGuard,
    CanLoadVGM,
    LocalNumberService
  ],
  declarations: [
    AppComponent,
    InfoPageComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
