import { Injectable, ComponentFactoryResolver, Inject, ComponentRef, ViewContainerRef } from '@angular/core';

import { InfoModalComponent } from './info-modal.component';
import { ColorType } from 'app/system/consts';

type IModalEvent = (data?: any)  => void;

export interface IModalOptions {
  size?: string;
  title?: string;
  message: string;

  confirmText?: string;
  confirmType?: ColorType;

  cancelText?: string;
  cancelType?: ColorType;

  onConfirm?: IModalEvent;
  onClose?: IModalEvent;

  type?: ColorType;

  data?: any;
}

@Injectable()
export class InfoModalService {

  private viewContainerRef: ViewContainerRef;
  constructor( @Inject(ComponentFactoryResolver) private factoryResolver: ComponentFactoryResolver) { }

  public setViewContainerRef(viewContainerRef: ViewContainerRef): void {

    this.viewContainerRef = viewContainerRef;
  }

  public show(options?: IModalOptions): ComponentRef<InfoModalComponent> {

    const factory = this.factoryResolver.resolveComponentFactory(InfoModalComponent);

    const component = factory.create(this.viewContainerRef.injector);
    component.instance.options = options;
    component.instance.componentRef = component;
    component.instance.count = this.viewContainerRef.length + 1;

    this.viewContainerRef.insert(component.hostView);

    return component;
  }
}
