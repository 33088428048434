import { Injectable } from '@angular/core';

export interface ITokenInfo {
  email: string;
  exp: number;
  rememberMe: string;
}

@Injectable()
export class TokenData {

  private getStorageValue(item: string) {

    return sessionStorage.getItem(item) || localStorage.getItem(item);
  }

  public get email(): string {
    return this.getStorageValue('form_email');
  }

  public set email(value: string) {
    localStorage.setItem('form_email', value);
  }

  public get access_token(): string {
    return this.getStorageValue('access_token');
  }

  public get refresh_token(): string {
    return this.getStorageValue('refresh_token');
  }

  public get rememberMe(): boolean {
    return JSON.parse(localStorage.getItem('remember_me'));
  }

  public set rememberMe(value: boolean) {

    if (value) {
      localStorage.setItem('remember_me', 'true');
    } else {
      localStorage.removeItem('remember_me');
    }
  }

  public hasAccessToken(): boolean {

    return this.access_token != null;
  }

  public updateAnotherTabs() {

    localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
    localStorage.removeItem('sessionStorage');
  }

  public set(data: TokenData) {

    if (data) {

      const tokenData = this.decode();

      let store: Storage;
      let remove: Storage;
      const useLocalStorage: boolean = this.rememberMe;

      if (useLocalStorage) {
        store = localStorage;
        remove = sessionStorage;
      } else {
        store = sessionStorage;
        remove = localStorage;
      }

      store.setItem('access_token', data.access_token);
      store.setItem('refresh_token', data.refresh_token);

      if (!useLocalStorage) {
        this.updateAnotherTabs();
      }

      if (remove) {
        remove.removeItem('access_token');
        remove.removeItem('refresh_token');
      }
    } else {
      this.clearToken();
    }
  }

  public decode(): ITokenInfo {

    if (this.access_token) {
      const base64Url = this.access_token.split('.');
      if (base64Url instanceof Array && base64Url.length === 3) {
        const base64 = base64Url[1].replace('-', '+').replace('_', '/');
        try {
          return JSON.parse(window.atob(base64));
        } catch (e) {
          return null;
        }
      }
    }
    return null;
  }

  public clearToken() {

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    this.updateAnotherTabs();
  }
}
