import { ViewContainerRef, Component, ViewChild, OnInit } from '@angular/core';

import { InfoModalService } from './info-modal.service';
import { ColorType } from 'app/system/consts';

@Component({
  selector: 'app-info-modal-container',
  templateUrl: './info-modal-container.component.html'
})
export class InfoModalContainerComponent implements OnInit {

  @ViewChild('container', {
    read: ViewContainerRef 
  }) viewContainerRef: ViewContainerRef

  constructor(
    private service: InfoModalService
  ) { }

  public ngOnInit(): void {
    this.service.setViewContainerRef(this.viewContainerRef);
  }

  private addTest() {

    this.service.show({

      title: 'TITULO',
      message: 'HELLO!',
      confirmType: ColorType.Danger,
      confirmText: 'Show',
      onConfirm: () => this.service.show({ message: "OK" })
    });
  }
}
