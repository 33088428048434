import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import { FormService } from 'app/system/services/form.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[focusFirst]'
})
export class FocusFirstDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef,
    private formService: FormService
  ) { }


  ngAfterViewInit() {

    this.formService.focusFirst(this.elementRef);
  }
}
