export const NOT_FOUND = 'NotFound';
export const NEW_CONST = 'new';

export enum CustomerType {

  ShipperExporter = 1,
  All = 0,
  Consignee = 2,
  Notify = 3
}

export type ISelectEvent<T> = (item: T) => void;

export enum ColorType {
  Primary = 1,
  Secondary = 2,
  Success = 3,
  Danger = 4,
  Warning = 5,
  Info = 6,
  Light = 7,
  Dark = 8,
  White = 9
}

export interface IColorScheme {
  background: string;
  text: string;
}

const colorTypes = {};
colorTypes[ColorType.Primary] = <IColorScheme>{ background: 'primary', text: 'white' };
colorTypes[ColorType.Secondary] = <IColorScheme>{ background: 'secondary', text: 'white' };
colorTypes[ColorType.Success] = <IColorScheme>{ background: 'success', text: 'white' };
colorTypes[ColorType.Danger] = <IColorScheme>{ background: 'danger', text: 'white' };
colorTypes[ColorType.Warning] = <IColorScheme>{ background: 'warning', text: 'white' };
colorTypes[ColorType.Info] = <IColorScheme>{ background: 'info', text: 'white' };
colorTypes[ColorType.Light] = <IColorScheme>{ background: 'light', text: 'dark' };
colorTypes[ColorType.Dark] = <IColorScheme>{ background: 'dark', text: 'white' };
colorTypes[ColorType.White] = <IColorScheme>{ background: 'white', text: 'dark' };
export const ColorTypes = colorTypes;


